import { BaseConversation } from '@capturi/api-conversations'
import { useSingleUser } from '@capturi/stores'
import { Td, Tr } from '@capturi/ui-components'
import { Flex, Text, keyframes, useToken } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import UserAvatar from 'components/UserAvatar'
import UserCoachingLink from 'components/UserCoachingLink'
import { FC, MouseEvent, useRef } from 'react'
import formatSeconds from 'utils/formatSeconds'
import translateAdversusStatus from 'utils/translateAdversusStatus'

type Props = BaseConversation & {
  onSelectConversation: (uid: string, event: MouseEvent<HTMLElement>) => void
  showStatus: boolean
} & {
  backgroundColor?: string
  cursor?: string
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const ConversationTableRow: FC<Props> = (props) => {
  const {
    userUid,
    dateTime,
    duration,
    uid,
    subject,
    status,
    onSelectConversation,
    showStatus,
    backgroundColor,
    cursor,
  } = props

  const { name, profileImage } = useSingleUser(userUid)
  const didHaveFocusRef = useRef()
  const [primary] = useToken('colors', ['brand.primary'])

  const fadeColor = keyframes({
    from: { backgroundColor: `${primary}7a` },
    to: { backgroundColor: `${primary}00` },
  })

  const animation = !didHaveFocusRef.current
    ? 'none'
    : `${fadeColor} 2s ease 250ms`

  return (
    <Tr
      __css={{ animation }}
      key={uid}
      onClick={(e: MouseEvent<HTMLElement>) => {
        if (window.getSelection()?.isCollapsed) {
          onSelectConversation(uid, e)
        }
      }}
      backgroundColor={backgroundColor}
      cursor={cursor}
    >
      <Td>{i18n.date(dateTime, dateFormat)}</Td>
      <Td>{subject}</Td>
      {showStatus && <Td>{translateAdversusStatus(status)}</Td>}
      <Td>{formatSeconds(duration)}</Td>
      <Td>
        <Flex>
          <UserAvatar name={name} profileImage={profileImage} size="xs" />
          <UserCoachingLink
            uid={userUid}
            noOfLines={1}
            wordBreak="break-all"
            px={2}
          >
            <Text>{name}</Text>
          </UserCoachingLink>
        </Flex>
      </Td>
    </Tr>
  )
}
